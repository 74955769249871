import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory()

const clickPluginInstance = new ClickAnalyticsPlugin()
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
}

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    autoTrackPageVisitTime: true,
    // TODO will be resolved later if will be add to Config.json
    connectionString:
      'InstrumentationKey=1c996ef9-a40c-49cf-ba40-45b535f2a21c;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=d8406d67-12b7-4854-99bf-6323bf35dbff',
    cookieCfg: {
      enabled: true,
    },
    disableAjaxTracking: false,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
      [reactPlugin.identifier]: { history: browserHistory },
    },
    extensions: [reactPlugin, clickPluginInstance],
  },
})
appInsights.loadAppInsights()
appInsights.trackPageView()

// TODO will be resolved later
appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || {}
  env.tags['ai.cloud.role'] = '<app-role-frontend>'
  // custom props
  env.data = env.data || {}
  env.data['ms-appName'] = '<frontend-disocvery>'
  env.data['ms-user'] = '<frontend-auth-user>'
  env.data['ms-userid'] = '<frontend-auth-userid>'
})

export { reactPlugin, appInsights }
