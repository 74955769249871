export const getClearName = (userName: string) =>
  userName.replace(/[,.]/g, ' ').replace(/\s+/g, ' ')

export const getSignature = (userName: string) => {
  const name = getClearName(userName)

  const nameParts = name.split(' ')

  const signatureArray: string[] = []

  if (nameParts[0]) {
    signatureArray.push(nameParts[0].charAt(0))
  }
  if (nameParts[1]) {
    signatureArray.push(nameParts[1].charAt(0))
  }

  return signatureArray.join('').toUpperCase()
}
