import React, { useEffect, useRef, useState } from 'react'
import { Button, Popover, Tooltip } from '@mui/material'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { colors } from '@dis/styles'
import { cx } from '@emotion/css'
import { Paragraph } from '@dis/components'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { selectUserOid } from '@dis/redux/src/security/selectors'
import { useGetUserActivityPresenceList } from '@dis/hooks/src/useGetUserActivityPresenceList'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { UserActivityPresenceStatus } from '@dis/api/src/graphqlClient/gql/__generated__/QueriesGetUserActivityPresenceListQuery.graphql'
import { TFunction } from 'i18next'
import { JOURNEY_ACTIVE_USERS_PREVIEW_LENGTH } from '@dis/constants'
import { getClearName, getSignature } from '@dis/utils/src/users'
import { styles } from './styles'

/**
 * @timestamp - seconds
 */
type UserListItem = {
  activity: UserActivityPresenceStatus
  email: string
  id: string
  name: string
  signature: string
  timestamp: number
}

const countColor = (
  { activity, name, timestamp }: UserListItem,
  t: TFunction,
  desc1tKey: string,
  desc2tKey: string,
) => {
  let borderColor: string = colors.kpmg.cobaltLight

  let desc = t(desc1tKey, { activity, name })

  if (timestamp < 30) {
    borderColor = colors.semantic.green
  } else if (timestamp < 30 * 60) {
    borderColor = colors.gray.gray40
  } else {
    const tmp = Math.floor(timestamp / 60 / 30)
    const timeAmount = tmp * 30

    desc = t(desc2tKey, { name, timeAmount })
  }

  return {
    borderColor,
    desc,
  }
}

export const ActiveUserList = () => {
  const [me, setMe] = useState<UserListItem>()
  const [users, setUsers] = useState<UserListItem[]>([])

  const wrapperRef = useRef<HTMLDivElement>(null)

  const isRtl = useAppSelector(selectIsLanguageRtl)
  const tenantId = useAppSelector(selectSelectedTenantId)
  const journeyId = useAppSelector(selectSelectedJourneyId)
  const userId = useAppSelector(selectUserOid)

  const { t } = useTranslation()

  const { anchorEl, handleClose, handleOpen } = useAnchor()

  const { data, refetch } = useGetUserActivityPresenceList({
    skip: !tenantId || !userId || !journeyId,
  })

  const firstPart = users.slice(0, JOURNEY_ACTIVE_USERS_PREVIEW_LENGTH)
  const secondPart = users.slice(JOURNEY_ACTIVE_USERS_PREVIEW_LENGTH)

  useEffect(() => {
    let me: UserListItem | undefined = undefined

    const enhancedUserArray: UserListItem[] = (
      data?.items?.map(
        ({ userInfo: { email, name, id }, metadata: { lastHeartbeat, status } }): UserListItem => {
          const timestamp = (Date.now() - new Date(lastHeartbeat).getTime()) / 1000
          const clearName = getClearName(name)

          return {
            activity: status || 'INACTIVE',
            email,
            id,
            name: clearName,
            signature: getSignature(name),
            timestamp,
          }
        },
      ) || []
    )
      .filter((item) => {
        if (item.id === userId) {
          me = item
        }

        return item.id !== userId
      })
      .sort((a, b) => a.timestamp - b.timestamp)

    setMe(me)
    setUsers(enhancedUserArray)
  }, [data?.items, userId])

  // Call api twice after component mount to load fresh data because sending own status using useSetJourneyActivity
  // in journey takes some time and the previous data is missing information about my presence.
  useEffect(() => {
    const timeoutRef = setTimeout(refetch, 2_000)

    return () => {
      clearTimeout(timeoutRef)
    }
  }, [refetch])

  return (
    <div className={styles.root}>
      {firstPart.map((userObj, index) => {
        const { name, signature } = userObj

        const { borderColor, desc } = countColor(
          userObj,
          t,
          tKeys.journeys.activeUserList.currentStatus,
          tKeys.journeys.activeUserList.lastViewed,
        )

        return (
          <Tooltip
            arrow
            key={name}
            title={desc.toLowerCase()}
            classes={{ arrow: styles.tooltip, popper: styles.tooltip }}>
            <div
              key={name}
              className={styles.bubble}
              style={{ borderColor, zIndex: firstPart.length - index }}>
              {signature}
            </div>
          </Tooltip>
        )
      })}

      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={handleOpen}
          className={styles.mainButton}>
          +{secondPart.length}
        </Button>

        <Popover
          className={cx(styles.popover.root, {
            [styles.popover.rtl]: isRtl,
          })}
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: isRtl ? 'left' : 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: isRtl ? 'left' : 'right',
            vertical: 'top',
          }}
          onClose={handleClose}>
          <div>
            <div className={styles.meInfo}>
              <div className={styles.listItem.root}>
                <div className={styles.listItem.leftColumn}>
                  <div className={styles.bubble}>{me?.signature}</div>
                </div>
                <div className={styles.listItem.rightColumn}>
                  <div>
                    <Paragraph variant="small" fontWeight="bold">
                      {me?.name}
                    </Paragraph>
                    <Paragraph variant="tiny" fontWeight="regular">
                      {t(tKeys.journeys.activeUserList.you)}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div ref={wrapperRef} className={styles.list}>
              {secondPart.map((userObj) => {
                const { name, signature } = userObj

                const { borderColor, desc } = countColor(
                  userObj,
                  t,
                  tKeys.journeys.activeUserList.currentStatusShort,
                  tKeys.journeys.activeUserList.lastViewedShort,
                )

                return (
                  <div key={name} className={styles.listItem.root}>
                    <div className={styles.listItem.leftColumn}>
                      <div key={name} className={styles.bubble} style={{ borderColor }}>
                        {signature}
                      </div>
                    </div>
                    <div className={styles.listItem.rightColumn}>
                      <Paragraph variant="small" fontWeight="bold">
                        {name}
                      </Paragraph>
                      <Paragraph variant="tiny" fontWeight="regular">
                        {desc.toLowerCase()}
                      </Paragraph>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Popover>
      </div>
    </div>
  )
}
