export * from './date'
export { ExtendedError } from './ExtendedError'

export const removeHTMLTags = (text: string) => {
  return text.replace(/<\/?[^>]+(>|$)/g, ' ')
}

export const shortenText = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.substring(0, maxLength).concat('..') : text
}

export const typedObjectKeys = <T extends Record<string, any>>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as unknown as Array<keyof T>
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export const typedObjectEntries = <T extends Record<string, any>>(obj: T): Entries<T> => {
  return Object.entries(obj) as Entries<T>
}

/**
 * Similar to Array.map function but this is for objects.
 * Returns object with the same keys as the original object.
 */
export const typedObjectMap = <T extends Record<string, any>, U>(
  obj: T,
  fn: (key: keyof T, value: T[keyof T]) => U,
): Record<keyof T, U> => {
  const newObject: Partial<Record<keyof T, U>> = {}

  typedObjectEntries(obj).forEach(([key, value]) => {
    newObject[key] = fn(key, value)
  })

  return newObject as Record<keyof T, U>
}
