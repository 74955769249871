import ReactDOM from 'react-dom/client'
import './index.scss'
import '@dis/languages'
import { ErrorBoundary } from '@dis/components'
import {
  AppLoader,
  CentralModalDialog,
  CentralModalLoader,
  MsalProvider,
  ThemeProvider,
} from '@dis/modules'
import { RouterProvider } from '@dis/routing/src/RouterProvider'
import { CentralNotificationPopup } from '@dis/modules/src/CentralNotificationPopup/CentralNotificationPopup'
import { RelayEnvironmentProvider } from 'react-relay'
import { reactRelayEnvironment } from '@dis/api/src/graphqlClient/relayClient'
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js'
import { Redux } from './Redux'
import { reactPlugin } from './ApplicationInsightsService'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AppInsightsErrorBoundary
    onError={() => <h1>I believe something went wrong</h1>}
    appInsights={reactPlugin}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <ErrorBoundary>
        <Redux>
          <ThemeProvider>
            <RelayEnvironmentProvider environment={reactRelayEnvironment}>
              <AppLoader>
                <MsalProvider>
                  <RouterProvider />
                </MsalProvider>
              </AppLoader>
              <CentralModalLoader />
              <CentralModalDialog />
              <CentralNotificationPopup />
            </RelayEnvironmentProvider>
          </ThemeProvider>
        </Redux>
      </ErrorBoundary>
    </AppInsightsContext.Provider>
  </AppInsightsErrorBoundary>,
)
